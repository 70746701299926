.footer-section-root-container {
    background-image: url('../../../public/assets/images/material-backgrounds/bg-wood-large.png');
    background-size: cover;
    width: 100vw;
    --footer-section-h-padding: 0 5vw;
    z-index: 2;
    position: relative;
    display: flex;
    background-repeat: no-repeat;
    padding-bottom: 5vh;
}

.footer-section-mission-statement-container {
    width: calc(max(375px, 40vw));
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 7.5vh;
    padding-bottom: 5vh;
}

.footer-section-mission-statement-header {
    padding-bottom: 2vh;
    padding: var(--footer-section-h-padding);
    background: var(--color-gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-section-mission-statement-body {
    line-height: 2.5;
    padding: var(--footer-section-h-padding);
}

.footer-section-get-in-touch-container {
    width: calc(max(375px, 45vw));
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-bottom: 10vh;
}

.footer-section-get-in-touch-header {
    padding: var(--footer-section-h-padding);
    padding-bottom: 1vh;
    background: var(--color-gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 90vw;
    line-height: 175%;
    font-weight: bold;
}

.footer-section-get-in-touch-body {
    padding: var(--footer-section-h-padding);
    padding-bottom: 3vh;
}

.footer-section-contact-list {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: var(--footer-section-h-padding);
}


.footer-section-contact-list-item {
    display: flex;
    gap: 2vw;
}

.footer-section-contact-icon-img {
    width: 30px;
    height: 30px;
    object-fit: fill;
}

.footer-section-contact-item-title {

}

.footer-section-download-app-container {
    padding-top: 10vh;
    width: calc(max(375px, 60vw));
    display: flex;
    gap: 4vw;
}

.footer-section-appstore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
}

.footer-section-apple-appstore-icon {
    width: 150px;
}

.footer-section-apple-appstore-screenshot {
    width: calc(max(250px, 20vw));
    height: 60vh;
    object-fit: scale-down;
}
 
.footer-section-playstore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
}

.footer-section-google-playstore-icon {
    width: 150px;
}

.footer-section-google-playstore-screenshot {
    width: calc(max(250px, 20vw));
    height: 60vh;
    object-fit: scale-down;
}

.footer-section-download-icons-container-mobile {
    display: flex;
    flex-direction: column;
    padding: var(--footer-section-h-padding);
    padding-top: 8vh;
    gap: 2vh;
}


/************* mobile ******************/

@media (max-width: 600px) {
    .footer-section-mission-statement-container {
        width: 100vw;
    }
    
    .footer-section-get-in-touch-container {
        padding-bottom: 0;
    }
}
