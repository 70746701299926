.main-navbar-root-container {
    background-image: url("/public/assets/images/material-backgrounds/bg-wood-main.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 10vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    position: absolute;
}

.main-navbar-bg-image {
    width: 100%;
    height: 10vh;
}

.main-navbar-logo-company-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.main-navbar-logo-icon {
    height: 8vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.main-navbar-company-title {
    font-size: 3vh;
    font-family: "Header-1";
    justify-self: flex-start ;
    color: var(--color-primary-1);
    background: var(--color-gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.main-navbar-tagline {
    color: var(--color-text-primary);
    text-transform: uppercase;
}


/* ---------- mobile ---------- */

.main-navbar-hamburger-menu-btn {
    outline: none;
    border: none;
    background: none;
}

.main-navbar-hamburger-menu-img {
    height: 2.5vh;
    object-fit: scale-down;
}


.main-navbar-hamburger-menu-img {
    height: 2.5vh;
    object-fit: scale-down;
    padding-left: 5vw;
}

.main-navbar-logo-company-container-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
}

.main-navbar-drawer-menu-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    background-image: url('../../../public/assets/images/material-backgrounds/menu-drawer-wooden-board.png');
    width: 75vw;
    background-size: cover;
    display: flex;
    height: 100vh;
    --drawer-content-padding-left: 5vw;
    --menu-content-container-width: 77.5vw;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    transition: 0.75s ease-out;
    height: 100vh;
    position: fixed;
}

.main-navbar-drawer-menu-header-container {
    display: flex;
    height: 5vh;
    align-items: center;
    gap: 4vw;
    padding-left: var(--drawer-content-padding-left);
    padding-top: 3vh;
}

.main-navbar-drawer-menu-close-icon {
    height: 3vh;
}

.main-navbar-drawer-menu-header-title {
    color: var(--text-primary-1);
    font-weight: bold;
    background: var(--color-gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-navbar-menu-item-container {
    background-image: url('../../../public/assets/images/general/directory-wooden-sign-square-1.png');
    height: 10vh;
    width: var(--menu-content-container-width);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: var(--drawer-content-padding-left);
    background-position: center;
    margin-bottom: 2vh;
}

.main-navbar-menu-item-container-bg-a {
    background-image: url('../../../public/assets/images/general/directory-wooden-sign-square-1.png');
}

.main-navbar-menu-item-container-bg-b {
    background-image: url('../../../public/assets/images/general/directory-wooden-sign-square-2.png');
}

.main-navbar-menu-item-title {
    color: var(--color-text-secondary);
}

.main-navbar-menu-section-header {
    color: var(--color-primary-1);
    padding-left: var(--drawer-content-padding-left);
}

.main-navbar-contact-section-container {
    display: flex;
    flex-direction: column;
}

.main-navbar-contact-icons-container {
    background-image: url('../../../public/assets/images/general/directory-wooden-sign-square-1.png');
    height: 10vh;
    width: var(--menu-content-container-width);;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: var(--drawer-content-padding-left);
    background-position: center;
    gap: 7.5vw;
}

