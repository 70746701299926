.shop-item-feature-screen-root-container {
    height: 100vh;
}

.shop-item-feature-screen-bg-image {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.shop-item-feature-screen-container {
    display: flex;
    padding-top: 20vh;
    height: 90vh;
}

.shop-item-feature-highlight-img {
    width: 50vw;
    height: 80vh;
    object-fit: scale-down;
    z-index: 1;
    align-self: flex-end;
}

.shop-item-feature-req-form-container {
    background-image: url('../../../public/assets/images/material-backgrounds/paper-bg.png');
    background-size: cover;
    z-index: 1;
    --padding-h: 0 3vw;
    --padding-v: 4vh;
    width: 35vw;
    justify-content: space-between;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    height: 60vh;
    display: flex;
}

.shop-item-feature-req-form-heading-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.shop-item-feature-req-form-heading {
    padding: var(--padding-h);
    padding-top: var(--padding-v);
    color: var(--color-primary-1);
    background: var(--color-gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 175%;
}

.shop-item-feature-req-form-subheading {
    padding: var(--padding-h);
    color: var(--text-primary);
}

.shop-item-feature-req-form-input-section-container {
    padding: var(--padding-h);
    display: flex;
    flex-direction: column;
    gap: 2vh;
    display: flex;
    height: 30vh;
}

.shop-item-feature-req-form-send-btn {
    padding: var(--padding-h);
    padding-top: 5vh;
    align-self: center;
    justify-content: flex-end;
    margin-bottom: 1vh;
} 

.shop-item-feature-gallery-container {
    height: 100vh;
    display: flex;
    align-items: flex-start;
    overflow-x: hidden;
    width: 100vw;
}

.shop-item-feature-gallery-item-container {
    width: 33.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    height: 100vh;
}

.shop-item-feature-gallery-item-img {
    width: 20vw;
    object-fit: scale-down;
    padding-bottom: 5vh;
    height: 60vh;
}

.shop-item-feature-gallery-item-desc {
    color: var(--color-neutral-white);
    width: 17.5vw;
    text-align: center;
}

.shop-item-feature-gallery-close-btn-container {
    position: absolute;
    left: 4vw;
    top: 12.5vh;
    z-index: 5;
}

.shop-item-feature-req-sent-successfully-container {
    z-index: 5;
    display: flex;
    align-items: center;
}


.shop-item-feature-req-sent-successfully-img {
    object-fit: scale-down;
    width: 35vw;
}

.shop-item-feature-submit-new-request-btn {
    position: absolute;
    padding-left: 5vw;
    padding-top: 15vh;
    width: 25vw;
}



/********* mobile **********/

@media (max-width: 600px) {
    .shop-item-feature-screen-container {
        flex-direction: column;
        align-items: center;
        padding-top: 15vh;
        gap: 0;
        height: auto;
    }

    .shop-item-feature-highlight-img {
        width: 100vw;
        height: 70vh;
        object-position: bottom;
        object-fit: contain;
        transform: scale(1.3);
        transform-origin: left;
    }

    .shop-item-feature-screen-root-container {
        height: auto;
    }

    .shop-item-feature-req-form-container {
        width: 100vw;
        --padding-h: 0 8vw;
        z-index: 4;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    }

    .shop-item-feature-gallery-item-container {
        width: 100vw;
        transition: transform 0.5s linear;
    }

    .shop-item-feature-gallery-item-img {
        width: 100vw;
    }

    .shop-item-feature-gallery-item-desc {
        width: 100vw;
    }

    .shop-item-feature-req-sent-successfully-container {
        height: 70vh;
        background-image: url('../../../public/assets/images/material-backgrounds/paper-bg.png');
        width: 100vw;
        object-fit: fill;
        justify-content: center;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    }

    .shop-item-feature-req-sent-successfully-img {
        width: 80vw;
        box-shadow: 2px 2px 4px #00000040;
    }

    .shop-item-feature-submit-new-request-btn {
        width: auto;
        padding-left: 0;
    }
}

.shop-item-feature-page-desc-text {
    color: var(--color-primary-1);
    width: 75vw;
    line-height: 200%;
    text-align: center;
    z-index: 1;
    height: 15vh;
}

.shop-item-feature-page-more-info-text {
    height: 10vh;
    font-weight: bold;
    position: absolute;
    bottom: 7.5vh;
    z-index: 1;
    transform: translateX(4vw);
}


.shop-item-feature-popover-controls-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.shop-item-feature-popover-controls-prev-btn {
    position: absolute;
    left: 5vw;
    top: 50vh;
    z-index: 1000;
}

.shop-item-feature-popover-controls-prev-img {
    width: 15px;
    height: 15px;
    object-fit: scale-down;
}

.shop-item-feature-popover-controls-next-btn {
    position: absolute;
    right: 5vw;
    top: 50vh;
    z-index: 1000;
}

.shop-item-feature-popover-controls-next-img {
    width: 15px;
    height: 15px;
    object-fit: scale-down;
}