.primary-long-button-container {
    background-color: var(--color-primary-1);
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: none;
}

.primary-long-button-title {
    padding: 0px 10px;
    color: var(--color-neutral-white);
    text-transform: uppercase;
}

.standard-form-textfield-container {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.standard-form-input-label {
    color: var(--color-primary-1);
}

.standard-form-input {
    text-align: left;
    vertical-align: top;
    border: 0.35vh var(--color-primary-1);
    outline: solid 0.5px;
    height: 5vh;
    border-radius: 2px;
    padding: 0 10px;
}

.back-to-home-btn-container {
    background-image: url('../../public/assets/images/material-backgrounds/wooden-board.png');
    z-index: 1000;
    position: absolute;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 12.5vh;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    gap: 10px;
    border: none;
}

.back-to-home-icon-img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    padding: 0 0 0 2vw;
}

.back-to-home-btn-title {
    color: var(--color-neutral-white);
    padding: 0 2vw 0 0;
}

.standard-close-btn-container {
    display: flex;
    background: none;
    border: none;
    gap: 10px;
    align-items: center;
}

.standard-close-btn-icon-img {
    color: var(--color-neutral-white);
    width: 15px;
    height: 15px;
    object-fit: scale-down;
}

.standard-close-btn-text {
    color: var(--color-neutral-white);
}

.pagination-container {
    display: flex;
    gap: 1.5vw;
}

.pagination-circle {
    border: 1px solid white;
    width: 5px;
    height: 5px;
    border-radius: 5px;
  
}

