.contact-bar-root-container {
    background-image: url('./../../../public/assets/images/material-backgrounds/wooden-board.png');
    position: fixed;
    right: 0;
    top: 35vh;
    box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.4);
    z-index: 2;
}

@media (max-width: 600px) {
    .contact-bar-root-container {
        opacity: 0;
    }
}

.contact-bar-bg-img {
    width: 55px;
    height: 35vh;
    object-fit: fill;
}

.contact-icons-container {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.contact-icon-img {
    width: 25px;
    height: 25px;
    object-fit: fill;
}