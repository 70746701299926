@font-face {
    font-family: "Header-1";
    src: url("../../public/assets/fonts/Rock_Salt/RockSalt-Regular.ttf");
}

@font-face {
    font-family: "Header-2";
    src: url("../../public/assets/fonts/Mochiy\ Pop\ One/MochiyPopOne-Regular.ttf");
}


@font-face {
    font-family: "Body-1";
    src: url("../../public/assets/fonts/Mukta/Mukta-Medium.ttf");
}

@font-face {
    font-family: "Label-1";
    src: url("../../public/assets/fonts/Mukta/Mukta-SemiBold.ttf");
}

:root {
    --s-device-width: 600px;
    --m-device-width: 768px;
    --l-device-width: 992px;
    --xl-device-width: 1200px;
}

:root {
    --color-primary-1: #3B4962;
    --color-secondary-1: #CA8C5F;
    --color-secondary-2: #636A54;
    --color-secondary-3: #D2BE8A;
    --color-text-primary: #363738;
    --color-text-secondary: #FFFFFF;
    --color-neutral-white: white;
    --color-neutral-1: #E5E3E1;
    --color-neutral-2: #CCCAC8;
    --color-neutral-4: #999490;
    --color-neutral-6: #666360;
    --color-neutral-7: #4D4946;
    --color-neutral-8: #33312F;
    --color-neutral-9: #1A1817;
    --color-neutral-black: #000000;
    --color-gradient-primary: -webkit-linear-gradient(0deg, #3B4962 36%, #636A54 100%);
}

:root {
    --font-size-xs: 0.5vh;
    --font-size-s: 1.5vh;
    --font-size-m: 1.8vh;
    --font-size-ml: 2.5vh;
    --font-size-l: 3vh;
    --font-size-xl: 3.5vh;
    --font-size-xxl: 5vh;
}

div {
    color: var(--color-text-primary)
}

.main-root-container {
    display: flex;
    width: 100vw;
    overflow-x: hidden;
}   

.main-sections-container {
    display: relative;
    flex-direction: column;
}


.text-primary-xl {
    font-size: var(--font-size-xl);
    font-family: 'Header-1';
    letter-spacing: 2px;
}

.text-primary-l {
    font-size: var(--font-size-l);
    font-family: 'Header-1';
    letter-spacing: 1.75px;
}

.text-primary-ml {
    font-size: var(--font-size-ml);
    font-family: 'Header-1';
    letter-spacing: 1.5px;
}

.text-primary-m {
    font-size: var(--font-size-m);
    font-family: 'Header-1';
    letter-spacing: 1.5px;
}

.text-primary-s {
    font-size: var(--font-size-s);
    font-family: 'Header-1';
    letter-spacing: 0.5px;
}

.text-primary2-xxl {
    font-size: var(--font-size-xxl);
    font-family: 'Header-2';
    letter-spacing: 1.75px;
}

.text-primary2-xl {
    font-size: var(--font-size-xl);
    font-family: 'Header-2';
    letter-spacing: 1.65px;
}

.text-primary2-l {
    font-size: var(--font-size-l);
    font-family: 'Header-2';
    letter-spacing: 1.5px;
}

.text-primary2-ml {
    font-size: var(--font-size-ml);
    font-family: 'Header-2';
    letter-spacing: 1px;
}

.text-primary2-m {
    font-size: var(--font-size-m);
    font-family: 'Header-2';
    letter-spacing: 1px;
}

.text-primary2-s {
    font-size: var(--font-size-s);
    font-family: 'Header-2';
    letter-spacing: 0.5px;
}


.text-secondary-ml {
    font-size: var(--font-size-ml);
    font-family: 'Body-1';
    letter-spacing: 1px;
}

.text-secondary-m {
    font-size: var(--font-size-m);
    font-family: 'Body-1';
    letter-spacing: 0.75px;
}

.text-secondary-s {
    font-size: var(--font-size-s);
    font-family: 'Body-1';
    letter-spacing: 0.5px;
}


.text-label-xl {
    font-family: 'Label-1';
    font-size: 2vh;
    text-transform: uppercase;
    letter-spacing: 1.75px;
}


.text-label-l {
    font-family: 'Label-1';
    font-size: 1.75vh;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.text-label-ml {
    font-family: 'Label-1';
    font-size: 1.65vh;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.text-label-m {
    font-family: 'Label-1';
    font-size: 1.4vh;
    text-transform: uppercase;
    letter-spacing: 1.25px;
}

.text-label-s {
    font-family: 'Label-1';
    font-size: 1.5vh;
    text-transform: uppercase;
    letter-spacing: 1px;
}



/**** misc *****/


.flex-spacer {
    flex-grow: 1;
}

button {
    outline: none;
    background: none;
    border: none;
}

a {
    text-decoration: none;
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
  
    70% {
      transform: scale(1.1);
    }
  
    100% {
      transform: scale(1);
    }
  }