.home-screen-root-container {
    height: 100vh;
    --entry-point-width-mobile: 85vw;
}

.home-screen-bg-image {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    position: absolute;
}

.entry-point-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    color: gray;
    left: 0;
    justify-content: center;
    position: absolute;
    align-items: flex-end;
    --entry-point-width: 17.5vw;
    --entry-point-height: 65vh;
}

.entry-point-phone-container {
    width: max(var(--entry-point-width), 275px);
    height: var(--entry-point-height);
    z-index: 1;
    position: absolute;
    bottom: 17.5vh;
}

.entry-point-phone-border-img {
    height: var(--entry-point-height);
    width: max(var(--entry-point-width), 275px);
    object-fit: fill;
    position: absolute;
    transition: 1s linear;
    scale: 100%;
}

@media (max-width: 600px) {
    .entry-point-phone-container {
        width: var(--entry-point-width-mobile);
    }

    .entry-point-phone-border-img {
        height: 75vh;
        width: var(--entry-point-width-mobile);
    }
}

.entry-point-phone-wood-img {
    height: var(--entry-point-height);
    width: max(var(--entry-point-width), 275px);
    object-fit: fill;
    position: absolute;
    border-radius: 2vw;
    scale: 0.85 0.9;
    transition: 0.25s linear;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

@media (max-width: 600px) {
    .entry-point-phone-wood-img {
        height: 75vh;
        width: var(--entry-point-width-mobile);
    }
}

.entry-point-content-container {
    position:absolute;
    height: var(--entry-point-height);
    width:  max(var(--entry-point-width), 275px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    transition: 0.1s linear;
}

@media (max-width: 600px) {
    .entry-point-content-container {
        height: 55vh;
        width: var(--entry-point-width-mobile);
    }

    .entry-point-phone-container {
        bottom: 20vh;
    }
}

.entry-point-logo-icon-container {

}

.entry-point-logo-icon {
    width: 100px;
    height: 100px;
    object-fit: contain;
    padding-top: 20px;
}

.entry-point-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.entry-point-title {
    font-size: 3vh;
    font-family: "Header-1";
    color: var(--color-primary-1);
    background: var(--color-gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.entry-point-message {
    font-family: "Header-2";;
    color: var(--color-text-primary);
}

.entry-point-booth-container {
    display: flex;
    justify-items: center;
    justify-content: center;
}

.entry-point-booth-img {
    position: absolute;
    width: 60vw;
    height: 25vh;
    object-fit: fill;
    overflow-anchor: top;
    bottom: -2.5vh;
    scale: 1.2;
    transition: 0.5s linear;
}

@media (max-width: 600px) {
    .entry-point-booth-img {
        opacity: 0;
    }
}

.entry-point-booth-message {
    position: absolute;
    bottom: 5vh;
    width: 35vw;
    text-align: center;
    transition: 0.5s linear;
}
 
@media (max-width: 600px) {
    .entry-point-booth-message {
        opacity: 0;
    }
}

.entry-point-booth-message-on-board {
    opacity: 0;
    font-size: var(--font-size-s);
}

@media (max-width: 600px) {
    .entry-point-booth-message-on-board {
        position: absolute;
        text-align: center;
        transition: 0.5s linear;
        width: 40vw;
        bottom: 15vh;
        z-index: 2;
        opacity: 1;
    }
}

.directory-menu-bar-container {
    display: flex;
    position: absolute;
    top: 25vh;
    width: 22vw;
    flex-direction: column;
    gap: 4vh;
    transition: 0.5s linear;
}

@media (max-width: 600px) {
    .directory-menu-bar-container {
        opacity: 0;
    }
}

.directory-menu-bar-title-container {
    background-image: url('../../../public/assets/images/material-backgrounds/wooden-board-light.png');
    width: 20vw;
    height: 8vh;
    display: flex;
    align-items: center;
    padding-left: 2vw;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.directory-menu-bar-title {
    color: var(--color-primary-1);
}

.directory-menu-bar-items-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.directory-menu-bar-item-btn-container {
    height: 8vh;
    width: 22vw;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
}

.directory-menu-bar-item-img {
    object-fit: fill;
    height: 10vh;
    width: 22vw;
}

.directory-menu-bar-item {
    color: var(--color-neutral-white);
    position: absolute;
    padding-left: 2vw;
}